import React, { FunctionComponent } from 'react';
import { Exhibit, Layout } from '../components';

const ExhibitPage: FunctionComponent = () => (
  <Layout>
    <Exhibit />
  </Layout>
);

export default ExhibitPage;
